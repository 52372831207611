<template>
  <div flex="~ items-center justify-between" p="x-4 y-2">
    <!-- <NuxtLink :to="{ path: '/', query }"> -->

    <div flex="~ items-center" font="500" text="xs white">
      <template v-if="query.campaign_id">
        <img v-if="cobrand.logo" :src="cobrand.logo?.replace(/^http:\/\//i, 'https://')" m="r-1" class="object-cover align-middle" w="10 md:16" />
        <span v-else class="text-white md:ml-2 md:text-lg">{{ cobrand.name || t('key.business.user.header.cobrand') }}</span>
        <span mx="1.5 md:4" w="1px" bg="white" h="4 md:10"></span>

        <span class="md:text-sm" mr="1 md:2.5">{{ t('key.business.user.header.poweredBy') }}</span>
      </template>
      <NuxtLink :to="{ path: '/attractions', query }">
        <img v-if="query.campaign_id" w="10 md:13" self="end md:center" class="xl:mt-2" src="~assets/icons/azgo-logo-white.png" />
        <img v-else w="12 md:18" self="end md:center" class="xl:mt-2" src="~assets/icons/azgo-logo-white.png" />
      </NuxtLink>
    </div>

    <div v-if="showCurrency" flex="~ items-center">
      <template v-if="isMobile">
        <div class="cursor-pointer" flex="~ items-center" @click="showPopup = true">
          <span text="xs lg:xl white" class="align-middle font-800 underline">
            {{ currentCurrency }}
          </span>
          <span text="lg lg:3xl white" ml="0.5 lg:2.5" class="align-middle">$</span>
          <!-- <van-image :width="20" :height="20" :src="UserIcon" class="align-middle ml-2"></van-image> -->
        </div>
        <van-popup v-if="isMobile" v-model:show="showPopup" position="bottom" closeable safe-area-inset-bottom round>
          <div class="px-4 py-3 text-center align-middle text-lg font-500">
            {{ t('key.business.user.header.selectCurrency') }}
          </div>
          <div class="px-4">
            <div class="mb-3 pt-4 text-[#121212] font-500">
              {{ t('key.business.user.header.allCurrency') }}
            </div>
            <van-radio-group v-model="currentCurrency" class="pb-4">
              <BaseAZRadio v-for="item in currencyInfo" :key="item.currency" :name="item.currency">
                <div class="flex items-center p-2 text-sm text-[#121212]">
                  <div class="w-[62px] text-base font-500">
                    {{ item.currency }}
                  </div>
                  <div class="text-base">
                    {{ item.fullName }}
                  </div>
                </div>
              </BaseAZRadio>
            </van-radio-group>
          </div>
        </van-popup>
      </template>
      <div v-else flex="~ items-center nowrap" space-x-30px>
        <BaseAZPopover v-model:open="showPopup" align="end" :align-offset="-100" :side-offset="16">
          <template #reference>
            <div class="cursor-pointer" @click.stop="showPopup = !showPopup">
              <span text="xl white" class="align-middle font-800 underline">
                {{ currentCurrency }}
              </span>
              <span text="3xl white" ml="2.5" class="align-middle">$</span>
            </div>
          </template>
          <template #default>
            <div class="px-4 text-left align-middle text-lg font-500">
              {{ t('key.business.user.header.selectCurrency') }}
            </div>
            <div class="px-4">
              <div class="mb-3 pt-4 text-[#121212] font-500">
                {{ t('key.business.user.header.allCurrency') }}
              </div>
              <van-radio-group v-model="currentCurrency" class="columns-3">
                <BaseAZRadio v-for="item in currencyInfo" :key="item.currency" :name="item.currency">
                  <div class="flex items-center p-2 pl-0 text-sm text-[#121212]">
                    <div class="mx-1 w-12 text-base font-500">
                      {{ item.currency }}
                    </div>
                    <div class="text-base">
                      {{ item.fullName }}
                    </div>
                  </div>
                </BaseAZRadio>
              </van-radio-group>
            </div>
          </template>
        </BaseAZPopover>
      </div>
      <ClientOnly>
        <NuxtLink v-if=" supportDirectBooking" :to="`/search-bookings/${locale}.html`">
          <div class="cursor-pointer" flex="~ items-center" font="600" text="xs md:xl white" m-l="2 md:8">
            <span v-if="!isMobile" m="r-1">{{ t('key.search.title') }}</span>
            <img src="~assets/icons/icon-search.png" h="4 md:7" w="4 md:7" alt="">
          </div>
        </NuxtLink>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  showCurrency: {
    type: Boolean,
    default: true,
  },
})

const { locale } = useLocale()
const { t } = useI18n()
const { query } = useRoute()
const router = useRouter()
const isMobile = useMobileByScreen()
// const { isWebview, checkWebview } = useJustifyWebview()
// checkWebview()

const showPopup = ref(false)

const userInfoStore = useUserStore()
const commonDataStore = useCommonStore()
const supportDirectBooking = computed(() => !!commonDataStore.commonData?.partnerInfo?.supportDirectBooking)

const currencyInfo = computed(() => commonDataStore.commonData?.currencyInfo || [])
const currentCurrency = computed({
  get: () => userInfoStore.userInfo.currency,
  set: (value: string) => {
    fetchChageCurrency(value)

    userInfoStore.userInfo.currency = value
    showPopup.value = false

    // 更新 URL 中的 currency 参数
    router.replace({
      query: {
        ...query,
        currency: value,
      },
    })
  },
})

function fetchChageCurrency(currency: string) {
  const { $fetch } = useNuxtApp()

  $fetch('/api/currency-change', {
    method: 'post',
    body: {
      currency,
    },
  })
}

const cobrand = computed(() => {
  const { partnerInfo } = commonDataStore.commonData || {}
  return {
    logo: partnerInfo?.distLogo,
    name: partnerInfo?.distName,
  }
})
</script>
